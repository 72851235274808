var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [
          _c("FilterSelector", {
            attrs: {
              buttonsToDisplay: [
                _vm.buttonEnum.TODAY,
                _vm.buttonEnum.SEVEN_DAYS,
                _vm.buttonEnum.MONTH_TO_DATE,
                _vm.buttonEnum.CUSTOM,
              ],
              extraFunction: true,
              extraFunctionLabel: "IMPRIMIR",
              disableExtraFunction: this.tableCollection.length === 0,
            },
            on: {
              searchData: function ($event) {
                return _vm.searchMusicCollection()
              },
              extraFunction: function ($event) {
                return _vm.printReport()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "filters",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-large-size-20 md-xlarge-size-20 print-hide",
                        staticStyle: { padding: "0" },
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass: "md-layout-item md-size-100",
                            staticStyle: {
                              "font-size": "1rem",
                              "text-align": "left",
                              margin: "25px 0 10px 0",
                              padding: "0",
                              "font-weight": "100",
                            },
                          },
                          [_vm._v(" Música ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "load-data",
                            attrs: { id: "load-data" },
                          },
                          [
                            _c("img", {
                              attrs: { src: require("@/assets/loading.gif") },
                            }),
                          ]
                        ),
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.items,
                            "auto-select-one-item": false,
                            "get-label": _vm.getLabel,
                            "min-len": 3,
                            "component-item": _vm.template,
                            placeholder: "Digite o nome da música",
                          },
                          on: {
                            "update-items": _vm.updateItems,
                            "item-selected": _vm.setMusic,
                            change: _vm.inputSearch,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [_c("Spinner")],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.loading &&
                _vm.blockStatus == 1 &&
                _vm.tableCollection.length > 0,
              expression:
                "!loading && blockStatus == 1 && tableCollection.length > 0",
            },
          ],
          staticClass: "md-layout-item md-size-30 print-hide",
          staticStyle: { "max-height": "450px" },
        },
        [
          _c("ReportCardTableView", {
            attrs: {
              title: "Estatísticas gerais",
              dataDisplay: "short-table",
              keys: ["label", "data"],
              cardData: [
                {
                  label: "Total de likes",
                  data: this.totalLikes,
                },
                {
                  label: "Total de dislikes",
                  data: this.totalDislikes,
                },
                {
                  label: "Total de votos",
                  data: this.totalVotes,
                },
                {
                  label: "Total de reproduções",
                  data: this.totalPlayed,
                },
                {
                  label: "Total de pedidos",
                  data: this.totalRequests,
                },
              ],
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.loading &&
                _vm.blockStatus == 1 &&
                _vm.tableCollection.length > 0,
              expression:
                "!loading && blockStatus == 1 && tableCollection.length > 0",
            },
          ],
          staticClass: "md-layout-item md-size-70 print-hide",
        },
        [
          _c("ReportCardTableView", {
            attrs: {
              title: "Extrato por hora",
              dataDisplay: "long-table",
              labels: [
                "Hora",
                "Likes",
                "Dislikes",
                "votos",
                "Reproduções",
                "Pedidos",
              ],
              keys: [
                "hour",
                "total_likes",
                "total_dislikes",
                "total_votes",
                "total_played",
                "total_requests",
              ],
              cardData: this.tableCollection,
            },
          }),
        ],
        1
      ),
      _c("block", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.blockStatus == 0,
            expression: "blockStatus == 0",
          },
        ],
      }),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-100" },
        [
          _c(
            "md-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.msgError,
                  expression: "msgError",
                },
              ],
              staticStyle: { "text-align": "center" },
            },
            [
              _c("md-card-content", [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Sem informações pra serem exibidas."),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "print" },
        [
          _c("TemplateReportExtractMusical", {
            attrs: {
              radioName: _vm.nameRadioReport,
              radioLogo: this.radio.logo,
              title: this.artistSelect.title,
              artist: this.artistSelect.artist,
              periodStart: this.startDate.toLocaleDateString("pt-BR"),
              periodEnd: this.endDate.toLocaleDateString("pt-BR"),
              data: {
                totalLikes: this.totalLikes,
                totalDislikes: this.totalDislikes,
                totalVotes: this.totalVotes,
                totalPlayed: this.totalPlayed,
                totalRequests: this.totalRequests,
                collectionPerHour: this.tableCollection,
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }