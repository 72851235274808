<template>
  <div class="listener-search-container">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">Buscar Ouvintes</h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-xsmall-size-100 md-size-20">
            <md-field>
              <label>Nome</label>
              <md-input v-model="filters.nameQuery" @input="onSearchInput"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-size-20">
            <md-field>
              <label>E-mail</label>
              <md-input v-model="filters.emailQuery" @input="onSearchInput"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-size-20">
            <md-field>
              <label>Telefone</label>
              <md-input v-model="filters.phoneQuery" @input="onSearchInput"></md-input>
            </md-field>
          </div>
        </div>

        <div v-if="loadingListeners">
          <div class="md-layout-item md-size-100">
            <Spinner />
          </div>
        </div>

        <div class="results-table" v-else-if="listeners.length > 0">
          <md-table v-model="listeners" md-sort="name" md-card>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Nome" md-sort-by="name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="E-mail" md-sort-by="email">{{ item.email }}</md-table-cell>
              <md-table-cell md-label="Telefone">{{ item.mobile_phone }}</md-table-cell>
              <md-table-cell md-label="Data de nascimento">{{ item.birth_date? new Date(item.birth_date).toLocaleDateString("pt-BR") : "Não informado" }}</md-table-cell>
              <md-table-cell md-label="Gênero">{{ item.gender === 'male' ? "Masculino" : item.gender === "female" ? "Feminino": "Não Informado" }}</md-table-cell>
              <md-table-cell>
                <el-button size="mini" type="primary" @click="openEditDialog(item)">
                  Editar
                </el-button>
              </md-table-cell>
              <md-table-cell>
                <el-button size="mini" type="danger" @click="confirmDeleteListener(item)">
                  Remover
                </el-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>


        <!-- Nenhum resultado encontrado -->
        <div v-if="searchExecuted && listeners.length === 0 && !loadingListeners" class="no-results">
          <p>Nenhum ouvinte encontrado com os critérios informados.</p>
        </div>

        <!-- Busca não iniciada -->
        <div v-else-if="listeners.length === 0 && !loadingListeners && !searchExecuted" class="no-results">
          <p>Digite um nome, e-mail ou telefone para buscar um ouvinte.</p>
        </div>
      </md-card-content>
    </md-card>

    <!-- Dialog para editar dados -->
    <el-dialog title="Editar Usuário" :visible.sync="editDialogVisible" width="50%">
      <el-form :model="editForm" :rules="rules" ref="editForm" label-position="top">      
        <el-form-item label="Nome" prop="first_name">
          <el-input v-model="editForm.first_name"></el-input>
        </el-form-item>
        <el-form-item label="Sobrenome" prop="last_name">
          <el-input v-model="editForm.last_name"></el-input>
        </el-form-item>
        <el-form-item label="E-mail" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Celular" type="number" prop="mobile_phone">
          <el-input v-model="editForm.mobile_phone" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="Data de Nascimento" prop="birth_date">
          <el-date-picker
            v-model="editForm.birth_date"
            type="date"
            placeholder="Data de Nascimento"
            format="dd/MM/yyyy"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="Gênero" prop="gender">
          <el-select v-model="editForm.gender" placeholder="Selecione o gênero">
            <el-option label="Masculino" value="male"></el-option>
            <el-option label="Feminino" value="female"></el-option>
            <el-option label="Prefiro não informar" value="undefined"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Senha" prop="password">
          <el-input v-model="editForm.password" type="password" show-password></el-input>
        </el-form-item>
        <el-form-item label="Confirmar senha" prop="confirmPassword">
          <el-input v-model="editForm.confirmPassword" type="password" show-password></el-input>
        </el-form-item>
      </el-form>
      <p v-if="errorEmptyField" class="form-error-title">Existem campos com dados não preenchidos ou inválidos. Verifique e tente novamente.</p>
      <p v-if="errorPasswordMismatch" class="form-error-title">As senhas não conferem. Por favor, verifique e tente novamente.</p>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">Cancelar</el-button>
        <el-button type="primary" @click="updateListener">Salvar</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import models from "@/routes/models";
import jwt_decode from "jwt-decode";
import Spinner from "../../../components/Icons/Spinner.vue";
import Swal from "sweetalert2";
import formatDateToSQL from "../../../utils/formatDateToSQL";
import Logger from "../../../utils/Logger";

export default {
  components: {
    Spinner
  },
  data() {
  return {
    radio: jwt_decode(localStorage.getItem("APP_01")).radio,
    filters: {
      nameQuery: "",
      emailQuery: "",
      phoneQuery: ""
    },
    listeners: [],
    searchExecuted: false,
    loadingListeners: false,
    editDialogVisible: false,
    editForm: {
      id: null,
      first_name: "",
      last_name: "",
      email: "",
      mobile_phone: "",
      birth_date: "",
      gender: "",
      password: "",
      confirmPassword: ""
    },
    rules: {
      first_name: [{ required: true, message: "Preencha esse campo", trigger: "blur" }],
      last_name: [{ required: true, message: "Preencha esse campo", trigger: "blur" }],
      email: [
        { required: true, message: "Preencha esse campo", trigger: "blur" },
        { type: "email", message: "Digite um e-mail válido", trigger: "blur" }
      ],
      mobile_phone: [
        { required: true, message: "Preencha esse campo", trigger: "blur" },
        {
          pattern: /^\d{11}$/,
          message: 'O telefone deve conter 11 dígitos sem caracteres alfabéticos',
          trigger: 'blur'
        }
      ],
      birth_date: [{ required: true, message: "Preencha esse campo", trigger: "change" }],
      gender: [{ required: true, message: "Preencha esse campo", trigger: "change" }]
    },
    errorEmptyField: false,
    errorPasswordMismatch: false,
    timerSearch: null
  };
},

  methods: {

    onSearchInput() {
      clearTimeout(this.timerSearch);

      this.timerSearch = setTimeout(() => {
        if (
          (this.filters.nameQuery && this.filters.nameQuery.length >= 3) ||
          (this.filters.emailQuery && this.filters.emailQuery.length >= 3) ||
          (this.filters.phoneQuery && this.filters.phoneQuery.length >= 3)
        ) {
          this.searchListeners();
        }
      }, 500);
    },
    searchListeners() {
      const params = new URLSearchParams({
        appKey: this.radio.key,
        nameQuery: this.filters.nameQuery,
        emailQuery: this.filters.emailQuery,
        phoneQuery: this.filters.phoneQuery
      });

      this.loadingListeners = true;

      models()
        .get(`/listeners/searchListeners?${params.toString()}`)
        .then((response) => {
          this.listeners = response.data;
          this.searchExecuted = true;
          this.loadingListeners = false;
        })
        .catch((error) => {
          console.error("Erro ao buscar ouvintes:", error);
        });
    },

    openEditDialog(listener) {
      this.editForm = { ...listener, password: "", confirmPassword: "" };
      this.editDialogVisible = true;
    },

    updateListener() {
      this.$refs.editForm.validate((valid) => {
        if(!valid){
          this.errorEmptyField = true;
          return;
        }
        this.errorEmptyField = false;

        const { password, confirmPassword, ...formData } = this.editForm;

        if (password && password !== confirmPassword) {
          this.errorPasswordMismatch = true;
          return;
        }
        this.errorPasswordMismatch = false;
      
        const payload = {
          ...formData,
          birth_date: formatDateToSQL(new Date(formData.birth_date)),
          password: password || undefined
        };


        Swal.fire({
          title: "Salvando...",
          text: "Aguarde enquanto os dados estão sendo atualizados.",
          icon: "info",
          allowOutsideClick: false,
          showConfirmButton: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        models()
          .post(`/listeners/updateListener`, payload)
          .then(() => {
            this.editDialogVisible = false;
            Swal.fire({
              title: "Sucesso!",
              text: "Os dados foram atualizados com sucesso.",
              type: "success",
            });

            this.filters.nameQuery = this.editForm.first_name + " " + this.editForm.last_name
            this.filters.phoneQuery = this.editForm.mobile_phone
            this.filters.emailQuery = this.editForm.email

            this.searchListeners()
          }).catch((err)=>{
            Logger.exception(err)
            Swal.fire({
              title: "Ops!",
              text: "Houve algum erro ao atualizar os dados do ouvinte.",
              type: "error",
            });

          })
      })
    },

    confirmDeleteListener(listener) {
      Swal.fire({
        title: "Você tem certeza?",
        text: `O usuário ${listener.name} (${listener.email}) será removido!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.value) {
          this.deleteListener(listener);
        }
      });
    },

    deleteListener(listener) {
      models()
        .delete(`/listeners/deleteListener/${listener.id}`)
        .then(() => {
          this.listeners = this.listeners.filter((l) => l.id !== listener.id);
        })
        .catch((error) => {
          console.error("Erro ao remover ouvinte:", error);
        });
    }
  }
};
</script>

<style scoped>

.listener-search-container {
  padding: 20px;
}
.results-table {
  margin-top: 20px;
}

.form-error-title {
  color: red;
  font-size: 18px;
  font-weight: lighter;
  margin-bottom: 16px;
}

.no-results {
  text-align: left;
  margin: 16px;
  font-size: 16px;
  color: #666;;
}
</style>
